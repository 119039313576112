import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import Drawer from "@mui/material/Drawer";
import { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { namespaces } from "../data";
import { CircularProgress } from "@mui/material";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import butterflyLogo from "../assets/butterflyLogo-blackAndWhite.png";

const BtnContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
`;

const Btn = styled.div`
  background-color: #eba607;
  color: #080808;
  font-size: 1.8rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const DrawerContainer = styled.div`
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    width: 100vw;
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 15px 10px;
  background-color: #eba607af;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const CloseIconContainer = styled.div`
  //   position: absolute;
  //   top: 10px;
  //   right: 5px;
  cursor: pointer;
`;

const Heading = styled.h3`
  font-size: 1.3rem;
  color: #080808;
  display: flex;
  align-items: center;
  gap: 5px;

  @media screen and (max-width: 550px) {
    font-size: 1rem;
  }
`;

const HeadingIcon = styled.img`
  width: 30px;

  @media screen and (max-width: 550px) {
    width: 20px;
  }
`;

const MainContent = styled.div`
  overflow: auto;
  height: calc(100vh - 128px);
  // height: 600px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MsgContainer = styled.div`
  max-height: 100%;
  position: relative;
`;

const UserIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 0;
  color: #eba607;
`;

const UserMsgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const UserMsg = styled.div`
  background-color: #eba607af;
  color: #080808;
  border-radius: 15px;
  max-width: 80%;
  width: fit-content;
  padding: 8px 12px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  display: inline-block;
  margin: 5px 0 10px;
  font-weight: 600;
  // box-shadow: 0.3px 0.3px 20px 0 silver;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
`;

const UserMsgSpan = styled.span``;

const BotIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 5px 0 0;
  color: #eba607;
`;

const BotMsgContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const BotMsg = styled.div`
  display: inline-block;
  max-width: 80%;
  // border: 2px solid #eba6078b;
  background-color: #eba6078b;
  color: #080808;
  border-radius: 15px;
  padding: 8px 15px;
  font-weight: 600;
  display: inline-block;
  box-shadow: 0.3px 0.3px 20px #f2f2f2;
  margin: 10px 0;
`;

const BotMsgSpan = styled.span``;

const InputContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-top: 2px solid #eba607af;
`;

const InputField = styled.input`
  width: 80%;
  outline: none;
  border: 2px solid #eba607af;
  background-color: #f2f2f2;
  padding: 10px 15px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 500;
`;

const SendBtn = styled.button`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #eba607af;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  // position: absolute;
  // bottom: 10px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // z-index: 5;
`;

const StopBtn = styled.button`
  display: block;
  margin: 5px auto 0;
  padding: 5px 12px;
  background-color: #eba607af;
  font-size: 0.9rem;
  border: none;
  borer-radius: 5px;
  cursor: pointer;
  font-weight: 600;
`;

const BotMessage = "Hi! I am here to help you!";

const ChatBot = () => {
  const [msgDrawer, setMsgDrawer] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [msg, setMsg] = useState([BotMessage]);
  const [currentNamespace, setCurrentNamespace] = useState(null);
  const [resLoading, setResLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const { pathname, search } = useLocation();
  const messageRef = useRef();

  useEffect(() => {
    scrollToBottom();
  }, [msg]);

  const scrollToBottom = () => {
    if (messageRef.current) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    }
  };

  const toggleDrawer = (open) => {
    setMsgDrawer(open);
  };

  useEffect(() => {
    const url = pathname + search;
    setCurrentNamespace(namespaces.find((item) => item.link.includes(url)));
  }, [pathname, search]);

  const fetchBotMsg = async (userInput) => {
    setResLoading(true);
    const res = await axios.post(
      "https://monarca-chatbot.onrender.com/v1/query",
      {
        pineconeIndexName: "index-monarca-1",
        namespace: currentNamespace.title.toLowerCase(),
        query: userInput,
      }
    );
    if (res.status === 200) {
      const answer = res.data.answer;
      handleBotResponse(
        answer.includes("<IDK>")
          ? "Please be precise with your question."
          : answer
      );
    }
    setResLoading(false);
  };

  const newMessage = async (e) => {
    setMsg((prev) => [...prev, userInput]);
    fetchBotMsg(userInput);
    setUserInput("");
  };

  const newMessageKey = (e) => {
    if (e.key === "Enter") {
      setMsg((prev) => [...prev, userInput]);
      fetchBotMsg(userInput);
      setUserInput("");
    }
  };

  var interval;
  const handleBotResponse = (botmsg) => {
    setTyping(true);
    let index = 0;
    let string = "";
    interval = setInterval(() => {
      if (index < botmsg.length) {
        let times = 10;
        for (let i = 0; i < times; i++) {
          string += botmsg.charAt(index + i);
        }
        if (index !== 0) {
          setMsg((prevstate) => prevstate.slice(0, -1));
        }
        setMsg((prevstate) => [...prevstate, string + " |"]);
        index = index + times;
      } else {
        setTyping(false);
        setMsg((prevstate) =>
          prevstate.map((c, i) => {
            if (i === prevstate.length - 1) {
              return c.replace("|", "");
            } else {
              return c;
            }
          })
        );
        clearInterval(interval);
      }
    }, 300);
  };

  return (
    <BtnContainer>
      <Btn onClick={() => toggleDrawer(true)}>
        <ChatOutlinedIcon />
      </Btn>
      <Drawer
        anchor="right"
        open={msgDrawer}
        onClose={() => toggleDrawer(false)}
        sx={{
          height: "100vh",
        }}
      >
        <DrawerContainer>
          <Header>
            <Heading>
              {/* <SmartToyOutlinedIcon
                sx={{ fontSize: "2rem", color: "#080808" }}
              /> */}
              <HeadingIcon src={butterflyLogo} alt="Butterfly Logo" />
              Mariposa Monarca Customer Care
            </Heading>
            <CloseIconContainer onClick={() => toggleDrawer(false)}>
              <CloseOutlinedIcon />
            </CloseIconContainer>
          </Header>
          <MainContent ref={messageRef}>
            <MsgContainer>
              {msg.map((message, i) =>
                i % 2 !== 0 ? (
                  <>
                    <UserIcon>
                      <FaceOutlinedIcon />
                    </UserIcon>
                    <UserMsgContainer>
                      <UserMsg>
                        <UserMsgSpan>{message}</UserMsgSpan>
                      </UserMsg>
                    </UserMsgContainer>
                    {resLoading && i === msg.length - 1 && (
                      <Loader>
                        <CircularProgress />
                      </Loader>
                    )}
                  </>
                ) : (
                  <>
                    <BotIcon>
                      <SmartToyOutlinedIcon />
                    </BotIcon>
                    <BotMsgContainer>
                      <BotMsg>
                        <BotMsgSpan>{message}</BotMsgSpan>
                      </BotMsg>
                    </BotMsgContainer>
                  </>
                )
              )}
            </MsgContainer>
            {/* {typing && <StopBtn onClick={stopResponse}>Stop</StopBtn>} */}
          </MainContent>
          <InputContent>
            <InputField
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={newMessageKey}
              disabled={typing}
            />
            <SendBtn onClick={newMessage} disabled={typing || !userInput}>
              <SendOutlinedIcon />
            </SendBtn>
          </InputContent>
        </DrawerContainer>
      </Drawer>
    </BtnContainer>
  );
};

export default ChatBot;
