import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Badge } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { flushUser } from "../redux/userSlice";
import { blogs, categories } from "../data";
import monarcafb from "../assets/monarcafb.png";
import { flushCart } from "../redux/cartSlice";
import setsNav from "../assets/sets-nav.JPEG";
import ringsNav from "../assets/rings-nav.jpeg";
import necklacesNav from "../assets/necklaces-nav.jpg";
import charmsNav from "../assets/charms-nav.JPEG";
import braceletsNav from "../assets/bracelets-nav.jpg";
import earringsNav from "../assets/Earrings-cat.jpg";
import specialSalesNav from "../assets/Special sale.JPEG";
import logo from "../assets/logo-monarca-negativo.png";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { IconButton, Snackbar } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

const CustomLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  width: 100%;
`;

const Container = styled.div`
  height: 100px;
  width: 100%;
  background-color: ${(props) => (props.t ? "transparent" : "#080808")};
  position: ${(props) => (props.t ? "absolute" : "relative")};
  top: ${(props) => (props.t ? "2rem" : "auto")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  overflow: visible;
  z-index: 10;
  border-bottom: ${(props) => (props.t ? "none" : "1px solid #555")};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  // gap: 1.2rem;
  margin-left: 2rem;

  @media screen and (max-width: 575px) {
    margin-left: 1rem;
  }
`;

const HamBtn = styled.button`
  color: #fff;
  padding: 0;
  background: transparent;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  display: none;

  @media (max-width: 1174px) {
    display: block;
  }
`;

const MuiDrawer = styled.div``;

const Brand = styled.img`
  height: 60px;
  // width: 140px;
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1174px) {
    display: none;
  }
`;

const NavLinkHolder = styled.div`
  margin-inline: 15px;
`;

const NavLink = styled.a`
  color: #fff;
  letter-spacing: 0.2rem;
  padding: 0.4rem 0;
  white-space: nowrap;
  font-family: "DM Sans", sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Dropdown = styled.div`
  background-color: #f2f2f2;
  position: absolute;
  top: 45px;
  left: -55px;
  width: 100px;
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
`;

const DropdownParent = styled.div`
  // padding-top: 0px;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 100;
`;
const SubOptionsDropdown = styled.div`
  display: flex;
  margin-top: 20px;
  height: 60vh;
  // height: 400px;
  background-color: #fff;
`;
const SubOptionsContainer = styled.div`
  padding: 15px 20px 20px;
  box-sizing: border-box;
  width: 30%;
  height: 98%;
  display: flex;
  // justify-content: center;
  overflow: auto;
`;

const SubOptionsListContainer = styled.div`
  height: 100%;
  gap: 10px;
  width: 100%;
`;
const SubOptionsTitle = styled.h3`
  text-transform: uppercase;
`;
const SubOptionsContent = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  gap: 0.8rem;
  // flex: 1;
  width: 100%;
  margin-bottom: 10px;
`;
const SubOptionIndividual = styled.a`
  color: gray;
  text-decoration: none;
  background-color: #f2f2f2;
  display: block;
  padding: 7px 10px 7px 3px;
  border-radius: 10px;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  &:hover {
    text-decoration: underline;
    background-color: #e8e8e8;
    cursor: pointer;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
`;

const Right = styled.div`
  display: flex;
  // align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 2rem;
`;
const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: "black",
    color: "white",
    fontWeight: 500,
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

export const mainOptions = [
  {
    id: 1,
    title: "home",
    linkTo: "/",
    imageUrl:
      "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    icon: <HomeOutlinedIcon />,
  },
  {
    id: 2,
    title: "collections",
    linkTo: "/products",
    imageUrl:
      "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    icon: <CollectionsOutlinedIcon />,
    open: false,
    subOptionsListTitle: "collections",
    subOptionsList: [
      // ...categories,
      {
        title: "All Collections",
        cat: null,
        img: monarcafb,
      },
      {
        title: "Rings",
        cat: "rings",
        img: ringsNav,
      },
      {
        title: "Necklaces",
        cat: "necklaces",
        img: necklacesNav,
      },
      {
        title: "Charms",
        cat: "charms",
        img: charmsNav,
      },
      {
        title: "Sets",
        cat: "sets",
        img: setsNav,
      },
      {
        title: "Special Sales",
        cat: "special sales",
        img: specialSalesNav,
      },
      {
        title: "Bracelets",
        cat: "bracelets",
        img: braceletsNav,
      },
      {
        title: "Earrings",
        cat: "earrings",
        img: earringsNav,
      },
    ],
  },
  {
    id: 3,
    title: "Blogs",
    linkTo: "/",
    imageUrl:
      "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    icon: <CollectionsOutlinedIcon />,
    open: false,
    subOptionsListTitle: "blogs",
    subOptionsList: [
      {
        title: "All Blogs",
        img: ringsNav,
        cat: null,
        link: "/blog",
      },
      ...blogs,
    ],
  },
  {
    id: 4,
    title: "contact",
    linkTo: "/contact",
    icon: <LocalPhoneOutlinedIcon />,
    imageUrl:
      "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    id: 5,
    title: "about",
    linkTo: "/about",
    icon: <PeopleOutlineOutlinedIcon />,
    imageUrl:
      "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
];

const Navbar = (props) => {
  const [hoveredOption, setHoveredOption] = useState(null);
  const [hoveredSubOption, setHoveredSubOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [navLinks, setNavLinks] = useState(mainOptions);
  const [openLogout, setOpenLogout] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const { state } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (state?.msg) {
      setOpenLogin(true);
    }
  }, []);

  useEffect(() => {
    if (openLogin) {
      setTimeout(() => {
        setOpenLogin(false);
      }, 2000);
    }
  }, [openLogin]);

  useEffect(() => {
    if (openLogout) {
      setTimeout(() => {
        setOpenLogout(false);
      }, 2000);
    }
  }, [openLogout]);

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit"></IconButton>
    </>
  );
  const classes = useStyles();
  const { quantity, sessionId } = useSelector((state) => state.persisted.cart);
  const user = useSelector((state) => state.persisted.user.currentUser);
  const userId = useSelector((state) => state.persisted.user.currentUser?._id);
  const admin = user && user.isAdmin;
  const blogAdmin = user && user.isBlogAdmin;
  const dispatch = useDispatch();

  const [drawerState, setDrawerState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  const list = () => (
    <Box
      sx={{
        width: 300,
        minHeight: "100vh",
        bgcolor: "#080808",
        color: "#fff",
        overflow: "auto",
      }}
      role="presentation"
    >
      <Brand
        alt="logo"
        src={logo}
        style={{ display: "block", margin: "10px auto" }}
      />
      <List>
        {navLinks.map((text, index) => (
          <ListItem key={text}>
            {text.subOptionsList ? (
              <div
                onClick={() =>
                  setNavLinks((prev) =>
                    prev.map((item) => {
                      if (item.id === text.id) {
                        return { ...item, open: !item.open };
                      } else {
                        return item;
                      }
                    })
                  )
                }
                style={{
                  backgroundColor: "#0f0f0f",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                <ListItemButton>
                  <ListItemIcon
                    style={{
                      marginRight: "5px",
                      minWidth: "auto",
                      color: "#fff",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ textTransform: "capitalize" }}
                    primary={text.title}
                  />
                  {text.open ? (
                    <KeyboardArrowUpOutlinedIcon />
                  ) : (
                    <KeyboardArrowDownOutlinedIcon />
                  )}
                </ListItemButton>
                {text.open &&
                  text.subOptionsList?.map((item) => (
                    <CustomLink
                      to={
                        text.title === "collections"
                          ? item.cat
                            ? `/products?category=${item.cat}`
                            : `/products`
                          : item?.link
                      }
                      onClick={toggleDrawer(false)}
                      style={{
                        backgroundColor: "#0f0f0f",
                        borderRadius: "10px",
                      }}
                    >
                      <ListItemButton style={{ marginLeft: "15px" }}>
                        <ListItemText
                          sx={{ textTransform: "capitalize" }}
                          primary={item.title}
                        />
                      </ListItemButton>
                    </CustomLink>
                  ))}
              </div>
            ) : (
              <CustomLink
                to={text.linkTo}
                onClick={toggleDrawer(false)}
                style={{ backgroundColor: "#0f0f0f", borderRadius: "10px" }}
              >
                <ListItemButton>
                  <ListItemIcon
                    style={{
                      marginRight: "5px",
                      minWidth: "auto",
                      color: "#fff",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{ textTransform: "capitalize" }}
                    primary={text.title}
                  />
                </ListItemButton>
              </CustomLink>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  function handleBodyClick() {
    setDropdownOpen(false);
  }

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(flushUser());
    dispatch(flushCart());
    setDropdownOpen(false);
    setOpenLogout(true);
  };

  return (
    <Container t={props.transparent}>
      <Drawer open={drawerState} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
      <Left>
        <HamBtn onClick={toggleDrawer(true)}>
          <MenuIcon />
        </HamBtn>
        <CustomLink to="/">
          <Brand alt="logo" src={logo} />
        </CustomLink>

        <NavLinks>
          {navLinks.map((option) => (
            <NavLinkHolder onMouseLeave={() => setHoveredOption(null)}>
              {option.subOptionsList ? (
                // <CustomLink>
                <NavLink
                  key={option.id}
                  onMouseEnter={() => setHoveredOption(option)}
                >
                  {option.title}
                </NavLink>
              ) : (
                // </CustomLink>
                <CustomLink to={option.linkTo}>
                  <NavLink
                    key={option.id}
                    onMouseEnter={() => setHoveredOption(option)}
                  >
                    {option.title}
                  </NavLink>
                </CustomLink>
              )}
            </NavLinkHolder>
          ))}
        </NavLinks>

        {hoveredOption && hoveredOption.subOptionsList && (
          <DropdownParent onMouseEnter={() => setHoveredOption(hoveredOption)}>
            <SubOptionsDropdown
              onMouseLeave={() => {
                setHoveredOption(null);
                setHoveredSubOption(null);
              }}
            >
              <SubOptionsContainer>
                <SubOptionsListContainer>
                  {hoveredOption.subOptionsListTitle && (
                    <SubOptionsTitle>
                      {hoveredOption.subOptionsListTitle}
                    </SubOptionsTitle>
                  )}
                  <SubOptionsContent>
                    {hoveredOption?.subOptionsList?.map((subOption) => (
                      <CustomLink
                        to={
                          hoveredOption.title === "collections"
                            ? subOption.cat
                              ? `/products?category=${subOption.cat}`
                              : `/products`
                            : subOption?.link
                        }
                        onClick={() => setHoveredOption(null)}
                      >
                        <SubOptionIndividual
                          key={subOption.title}
                          onMouseEnter={() => setHoveredSubOption(subOption)}
                          onMouseLeave={() => setHoveredSubOption(null)}
                        >
                          <ChevronRightOutlinedIcon />
                          {subOption.title}
                        </SubOptionIndividual>
                      </CustomLink>
                    ))}
                  </SubOptionsContent>
                </SubOptionsListContainer>
              </SubOptionsContainer>
              <ImageContainer>
                <Image
                  src={hoveredSubOption?.img || hoveredOption?.img || monarcafb}
                  alt={hoveredSubOption?.title || hoveredOption?.title}
                />
              </ImageContainer>
            </SubOptionsDropdown>
          </DropdownParent>
        )}
      </Left>
      <Right>
        {/* <SearchOutlinedIcon
          style={{ color: "white", width: "30px", height: "30px" }}
        /> */}

        {/* {userId && ( */}
        <CustomLink to={`/cart/${userId ? userId : sessionId}`}>
          <Badge badgeContent={quantity} classes={{ badge: classes.badge }}>
            <ShoppingBasketOutlinedIcon
              style={{ color: "white", width: "30px", height: "30px" }}
            />
          </Badge>
        </CustomLink>
        {/* )} */}

        <CustomLink
          to={!user && "/login"}
          onClick={user && handleDropdown}
          style={{ position: "relative" }}
        >
          <PersonOutlineOutlinedIcon
            style={{ color: "white", width: "30px", height: "30px" }}
          />
          {dropdownOpen && (
            <Dropdown>
              {admin && (
                <>
                  <CustomLink to="/dashboard" style={{ display: "block" }}>
                    Dashboard
                  </CustomLink>
                  {/* <br /> */}
                </>
              )}
              {blogAdmin && (
                <>
                  <CustomLink to="/edit-blog" style={{ display: "block" }}>
                    Edit Blog
                  </CustomLink>
                  {/* <br /> */}
                </>
              )}
              <CustomLink
                to="#"
                style={{ display: "block" }}
                onClick={handleLogout}
              >
                Logout
              </CustomLink>
            </Dropdown>
          )}
        </CustomLink>
      </Right>
      <Snackbar
        open={openLogout}
        autoHideDuration={2000}
        message="Logout Successfull!"
        action={action}
      />
      <Snackbar
        open={openLogin}
        autoHideDuration={2000}
        message={state?.msg}
        action={action}
      />
    </Container>
  );
};

export default Navbar;
