import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { PublicRoutes } from "./PublicRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { BlogAdminRoutes } from "./BlogAdminRoutes";
import {
  About,
  AcceptableReusePolicy,
  AmbassadorWholesale,
  Blog,
  Cart,
  Contact,
  CookiePolicy,
  Dashboard,
  DashboardChatbot,
  DashboardChatbotCategory,
  DashboardCoupons,
  DashboardEditCoupon,
  DashboardNewCoupon,
  DashboardNewProduct,
  DashboardOrder,
  DashboardOrders,
  DashboardProduct,
  DashboardProducts,
  DashboardUser,
  DashboardUsers,
  Disclaimer,
  EditBlog,
  Home,
  Login,
  PaymentSuccess,
  PrivacyPolicy,
  Product,
  Products,
  ReturnPolicy,
  ShippingPolicy,
  Signup,
  Sitemap,
  TermsAndConditions,
} from "../pages";
import {
  AntiqueSilverCharms,
  ArtOfLayering,
  CanadianRingSizes,
  CaringSilverJewelry,
  CoupleRings,
  EngagementRings,
  JewelryInCanada,
  NecklacesCanada,
  PerfectSilverJewelry,
  SilverChains,
  SilverCharms,
  SilverEarrings,
  SilverRings,
  SilverSets,
  TrendingJewelryStyles,
  ValentineGift,
  WeddingRings,
  WeddingSilverJewelry,
} from "../blogs";
import Banner from "../components/Banner";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { userRequest } from "../requestMethods";
import { useDispatch } from "react-redux";
import { flushUser } from "../redux/userSlice";
import { flushCart } from "../redux/cartSlice";
import { IconButton, Snackbar } from "@mui/material";

const NavLayout = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Banner transparent={pathname === "/" ? true : false} />
      <Navbar transparent={pathname === "/" ? true : false} />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
};

const NoNavLayout = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Outlet />
    </Suspense>
  );
};

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <MyRoutes />
    </BrowserRouter>
  );
}

export const MyRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    if (openLogin) {
      setTimeout(() => {
        setOpenLogin(false);
      }, 2000);
    }
  }, [openLogin]);
  // userRequest.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     const status = error.response ? error.response.status : null;

  //     if (status === 403) {
  //       // window.location.reload();
  //       setOpenLogin(true);
  //       throw error;
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  // function handleLogin() {
  //   navigate("/login");
  //   dispatch(flushUser());
  //   dispatch(flushCart());
  // }

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit"></IconButton>
    </>
  );

  return (
    <>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route element={<NavLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/ambassador-and-wholesale"
              element={<AmbassadorWholesale />}
            />
            <Route path="/products/:category" element={<Products />} />
            <Route path="/products" element={<Products />} />
            <Route path="/cart/:userId" element={<Cart />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/cookiePolicy" element={<CookiePolicy />} />
            <Route
              path="/termsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route path="/returnPolicy" element={<ReturnPolicy />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/shippingPolicy" element={<ShippingPolicy />} />
            <Route
              path="/acceptableReusePolicy"
              element={<AcceptableReusePolicy />}
            />
            <Route path="/blog">
              <Route index element={<Blog />} />
              <Route path="rings-in-canada" element={<SilverRings />} />
              <Route
                path="silver-charms-for-bracelets"
                element={<SilverCharms />}
              />
              <Route
                path="silver-chain-mens-canada"
                element={<SilverChains />}
              />
              <Route
                path="silver-earrings-canada"
                element={<SilverEarrings />}
              />
              <Route path="jewelry-sets-for-women" element={<SilverSets />} />
              <Route path="jewelry-in-canada" element={<JewelryInCanada />} />
              <Route
                path="engagement-rings-in-canada"
                element={<EngagementRings />}
              />
              <Route path="couple-rings-canada" element={<CoupleRings />} />
              <Route
                path="canadian-ring-sizes"
                element={<CanadianRingSizes />}
              />
              <Route
                path="antique-silver-charms"
                element={<AntiqueSilverCharms />}
              />
              <Route
                path="trending-jewelry-styles"
                element={<TrendingJewelryStyles />}
              />
              <Route
                path="wedding-rings-in-canada"
                element={<WeddingRings />}
              />
              <Route
                path="choosing-the-perfect-silver-jewelry-for-your-skin-tone"
                element={<PerfectSilverJewelry />}
              />
              <Route
                path="caring-for-your-silver-jewelry"
                element={<CaringSilverJewelry />}
              />
              <Route
                path="the-art-of-layering:creating-stylish-silver-jewelry-stacks"
                element={<ArtOfLayering />}
              />
              <Route
                path="silver-jewelry-for-a-wedding-day"
                element={<WeddingSilverJewelry />}
              />
              <Route
                path="silver-necklace-women-canada"
                element={<NecklacesCanada />}
              />
              <Route
                path="silver-chain-mens-canada"
                element={<SilverChains />}
              />
              <Route
                path="perfect-valentines-day-gift"
                element={<ValentineGift />}
              />
            </Route>
            <Route path="/sitemap" element={<Sitemap />} />
          </Route>
          <Route element={<NoNavLayout />}>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/paymentSuccess" element={<PaymentSuccess />} />
          </Route>
        </Route>
        <Route element={<AdminRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboardProducts" element={<DashboardProducts />} />
          <Route path="/dashboardUsers" element={<DashboardUsers />} />
          <Route path="/dashboardOrders" element={<DashboardOrders />} />
          <Route path="/dashboardProduct/:id" element={<DashboardProduct />} />
          <Route path="/dashboardUser/:id" element={<DashboardUser />} />
          <Route path="/dashboardOrder/:id" element={<DashboardOrder />} />
          <Route
            path="/dashboardNewProduct"
            element={<DashboardNewProduct />}
          />
          <Route path="/dashboardCoupons" element={<DashboardCoupons />} />
          <Route
            path="/dashboardEditCoupon/:id"
            element={<DashboardEditCoupon />}
          />
          <Route path="/dashboardNewCoupon" element={<DashboardNewCoupon />} />
          <Route path="/dashboardChatbot" element={<DashboardChatbot />} />
          <Route
            path="/dashboardChatbot/:category"
            element={<DashboardChatbotCategory />}
          />
        </Route>
        <Route element={<BlogAdminRoutes />}>
          <Route path="/edit-blog" element={<EditBlog />} />
        </Route>
      </Routes>
      {/* <Snackbar
        open={openLogin}
        autoHideDuration={2000}
        message={"Invalid Token! Please Login to continue."}
        action={action}
        onClick={handleLogin}
      /> */}
    </>
  );
};
