import { LinearProgress } from "@mui/material";
import { styled } from "styled-components";

const Container = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Loading = () => {
  return (
    <Container>
      <LinearProgress color="inherit" sx={{ width: "200px" }} />
    </Container>
  );
};

export default Loading;
