import React, { useEffect, useState } from "react";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import blog1 from "../assets/Blog 1.jpeg";
import blog2 from "../assets/Blog 2.jpeg";
import blog3 from "../assets/Blog 3.jpeg";
import { fadeVariants } from "../animations";
import {motion} from 'framer-motion'
import { getBlog } from "../redux/apiCalls";
import { useDispatch } from "react-redux";

const Container = styled(motion.div)`
  height: 100vh;
  display: flex;
  margin-block: 20px;
  width: 100%;

  @media (max-width: 992px) {
    height: auto;
    width: 100%;
    justify-content: center;
    margin: 50px auto;
  }
`;

const ImagesContainer = styled.div`
  flex: 2;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 992px) {
    display: none;
  }
`;
const Image = styled.img`
  width: 33.333%;
  height: 100%;
  object-fit: cover;
`;
const InfoContainer = styled.div`
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-inline: 2rem 1rem;
`;
const Name = styled.span`
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  letter-spacing: 0.2rem;
  margin-bottom: 15px;
  font-weight: 700;
`;
const Title = styled.h2`
  font-family: "DM Sans", sans-serif;
  font-size: 2.3rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #000;
  text-align: left;
  margin-bottom: 15px;
`;
const Desc = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0;
  color: #4a4848;
  text-align: left;
  font-weight: 500;
  margin-bottom: 15px;
`;
const LinkSpan = styled.span`
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;
const LinkContainer = styled.div`
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover ${LinkSpan} {
    text-decoration: underline;
  }
`;

const Models = () => {
  const [blogContent,setBlogContent] = useState({title:'',content:''})
  const dispatch = useDispatch()

  useEffect(()=>{
    const fetchBlogs = async()=>{
        const response = await getBlog(dispatch);
        if(response?.length>0){
            setBlogContent(response[0])
        }
    }
    fetchBlogs()
},[])
  return (
    <Container
    variants={fadeVariants}
    initial="initial"
    // animate="animate"
    whileInView="whileInView"
    >
      <ImagesContainer>
        <Image src={blog2} loading="lazy"/>
        <Image src={blog3} loading="lazy"/>
        <Image src={blog1} loading="lazy"/>
      </ImagesContainer>
      <InfoContainer>
        <Name>Mariposa Monarca Jewelry</Name>
        <Title>{blogContent.title}</Title>
        <Desc>
          {blogContent.content}
        </Desc>
        <LinkContainer>
          <KeyboardArrowRightIcon style={{ color: "#807b7b" }} />
          <LinkSpan>make it yours</LinkSpan>
        </LinkContainer>
      </InfoContainer>
    </Container>
  );
};

export default Models;

// https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940

// https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500
