import { lazy } from "react";

export { default as Home } from "./Home";
const Product = lazy(() => import("./Product"));
const Products = lazy(() => import("./Products"));
const Cart = lazy(() => import("./Cart"));
const Login = lazy(() => import("./Login"));
const Signup = lazy(() => import("./Signup"));
const PaymentSuccess = lazy(() => import("./PaymentSuccess"));
const Dashboard = lazy(() => import("./Dashboard"));
const DashboardProducts = lazy(() => import("./DashboardProducts"));
const DashboardProduct = lazy(() => import("./DashboardProduct"));
const DashboardNewProduct = lazy(() => import("./DashboardNewProduct"));
const DashboardUsers = lazy(() => import("./DashboardUsers"));
const DashboardUser = lazy(() => import("./DashboardUser"));
const DashboardOrders = lazy(() => import("./DashboardOrders"));
const DashboardOrder = lazy(() => import("./DashboardOrder"));
const About = lazy(() => import("./About"));
const Contact = lazy(() => import("./Contact"));
const AmbassadorWholesale = lazy(() => import("./AmbassadorWholesale"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const CookiePolicy = lazy(() => import("./CookiePolicy"));
const TermsAndConditions = lazy(() => import("./TermsAndConditions"));
const ReturnPolicy = lazy(() => import("./ReturnPolicy"));
const Disclaimer = lazy(() => import("./Disclaimer"));
const ShippingPolicy = lazy(() => import("./ShippingPolicy"));
const AcceptableReusePolicy = lazy(() => import("./AcceptableReusePolicy"));
const EditBlog = lazy(() => import("./EditBlog"));
const DashboardCoupons = lazy(() => import("./DashboardCoupons"));
const DashboardEditCoupon = lazy(() => import("./DashboardEditCoupon"));
const DashboardNewCoupon = lazy(() => import("./DashboardNewCoupon"));
const DashboardChatbot = lazy(() => import("./DashboardChatbot"));
const DashboardChatbotCategory = lazy(() =>
  import("./DashboardChatbotCategory")
);
const Sitemap = lazy(() => import("./Sitemap"));
const Blog = lazy(() => import("./Blog"));

export {
  // Home,
  Product,
  Products,
  Cart,
  Login,
  Signup,
  PaymentSuccess,
  Dashboard,
  DashboardProducts,
  DashboardProduct,
  DashboardNewProduct,
  DashboardUsers,
  DashboardUser,
  DashboardOrders,
  DashboardOrder,
  About,
  Contact,
  AmbassadorWholesale,
  PrivacyPolicy,
  CookiePolicy,
  TermsAndConditions,
  ReturnPolicy,
  Disclaimer,
  ShippingPolicy,
  AcceptableReusePolicy,
  EditBlog,
  DashboardCoupons,
  DashboardEditCoupon,
  DashboardNewCoupon,
  DashboardChatbot,
  DashboardChatbotCategory,
  Sitemap,
  Blog,
};
