import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import Loading from "../components/Loading";

export const BlogAdminRoutes = () => {
  const user = useSelector((state) => state.persisted.user?.currentUser);
  const blogAdmin = user && user.isBlogAdmin;

  if (!blogAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </>
  );
};
