import React from "react";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { motion } from "framer-motion";
import { Navigate, useNavigate } from "react-router-dom";

const Container = styled(motion.div)`
  flex: 1;
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;

  @media screen and (max-width: 400px) {
    height: auto;
    padding-bottom: 10px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 70%;
  position: relative;

  @media screen and (max-width: 575px) {
    height: 70%;
  }
`;

const Image1 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out; /* Add transition to the opacity property */
  position: relative;
  z-index: 3;

  &:hover {
    opacity: 0;
  }
`;

const Image2 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out; /* Add transition to the opacity property */
  position: absolute;
  z-index: 2;
  opacity: 1;
  top: 0;
  left: 0;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
const DateCategory = styled.span`
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: ${(props) => (props.date ? 700 : 600)};
  text-align: center;
  display: block;
  margin: 5px auto;
  background-color: #eba607;
  color: #080808;
  padding: 5px 7px;
  border-radius: 4px;
  z-index: 5;
  position: absolute;
  top: 7px;
  left: 12px;
`;
const Title = styled.h2`
  letter-spacing: 0.1rem;
  line-height: 2.1rem;
  margin: 0.5rem auto;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;
  height: 10vh;
  display: flex;
  align-items: center;
  color: #080808;

  @media screen and (max-width: 575px) {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
`;
const Description = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.1rem;
  color: gray;
  margin-bottom: 0.5rem;
`;

const ReadMoreSpan = styled.span`
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`;
const ReadMoreDiv = styled.div`
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover ${ReadMoreSpan} {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  width: 100%;
  display: block;
  padding: 13px 5px;
  font-size: 1rem;
  background-color: #eba607;
  color: #080808;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;

  &:hover {
    opacity: 0.9;
  }
`;

const BlogItem = ({ item }) => {
  const navigate = useNavigate();

  return (
    <Container
      transition={{ duration: 0.5 }}
      whileHover={{ y: -15, transition: { duration: 0.5 } }}
    >
      <DateCategory>{item.categories[0]}</DateCategory>
      <ImageContainer>
        <Image1 alt={item.title + " image"} src={item.imgs[0]} loading="lazy" />
        <Image2 alt={item.title + " image"} src={item.imgs[1]} loading="lazy" />
      </ImageContainer>
      <Details>
        {/* <DateCategory date>{item.date}</DateCategory> */}
        <Title>{item.title}</Title>
        {/* <Description>{item.desc}</Description> */}
        {/* <ReadMoreDiv>
          <KeyboardArrowRightIcon style={{ color: "#807b7b" }} />
          <ReadMoreSpan>read more</ReadMoreSpan>
        </ReadMoreDiv> */}
      </Details>
      <Button onClick={() => navigate(`/product/${item._id}`)}>
        View Product
      </Button>
    </Container>
  );
};

export default BlogItem;
