import React from "react";
import { styled } from "styled-components";
import {
  LineStyle,
  Timeline,
  TrendingUp,
  PermIdentity,
  Storefront,
  AttachMoney,
  BarChart,
  MailOutline,
  DynamicFeed,
  ChatBubbleOutline,
  WorkOutline,
  Report,
} from "@material-ui/icons";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo-monarca-negativo.png";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";

const CustomLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
`;

const Container = styled.div`
  width: 300px;
  height: 100vh;
  background-color: #080808;
  position: sticky;
  top: 0;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  padding: 20px;
  color: #ccc;
`;

const Menu = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: rgb(187, 186, 186);
`;

const SidebarList = styled.ul`
  list-style: none;
  padding: 5px;
`;

const SidebarListItem = styled.li`
  padding: 10px 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  font-size: 18px;
  margin-block: 0.5rem;
  font-weight: 500;
  &:hover {
    background-color: rgb(240, 240, 255);
    color: #080808;
  }
`;

const Brand = styled.img`
  width: 80%;
`;

const links = [
  { title: "Home", href: "/dashboard", icon: <LineStyle /> },
  { title: "Users", href: "/dashboardUsers", icon: <PermIdentity /> },
  { title: "Products", href: "/dashboardProducts", icon: <Storefront /> },
  { title: "Orders", href: "/dashboardOrders", icon: <AttachMoney /> },
  { title: "Coupons", href: "/dashboardCoupons", icon: <SellOutlinedIcon /> },
  {
    title: "Chat Bot",
    href: "/dashboardChatbot",
    icon: <SmartToyOutlinedIcon />,
  },
];

const Sidebar = () => {
  const { pathname } = useLocation();
  return (
    <Container>
      <Wrapper>
        <Menu>
          <Title>
            <CustomLink to="/">
              <Brand alt="logo" src={logo} />
            </CustomLink>
          </Title>
          <SidebarList>
            {links.map((link) => {
              const activeStyles =
                link.href === pathname
                  ? { backgroundColor: "rgb(240, 240, 255)", color: "#080808" }
                  : {};
              return (
                <CustomLink to={link.href} className="link">
                  <SidebarListItem style={activeStyles}>
                    {link.icon}
                    {link.title}
                  </SidebarListItem>
                </CustomLink>
              );
            })}
            {/* <CustomLink to="/dashboard" className="link">
              <SidebarListItem>
                <LineStyle style={{ marginRight: "5px", fontSize: "20px" }} />
                Home
              </SidebarListItem>
            </CustomLink>
            <CustomLink to="/dashboardUsers">
              <SidebarListItem>
                <PermIdentity
                  style={{ marginRight: "5px", fontSize: "20px" }}
                />
                Users
              </SidebarListItem>
            </CustomLink>
            <CustomLink to="/dashboardProducts">
              <SidebarListItem>
                <Storefront style={{ marginRight: "5px", fontSize: "20px" }} />
                Products
              </SidebarListItem>
            </CustomLink>
            <CustomLink to="/dashboardOrders">
              <SidebarListItem>
                <AttachMoney style={{ marginRight: "5px", fontSize: "20px" }} />
                Orders
              </SidebarListItem>
            </CustomLink> */}
          </SidebarList>
        </Menu>
      </Wrapper>
    </Container>
  );
};

export default Sidebar;
