import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    openModal: false,
  },
  reducers: {
    toggle: (state) => {
      state.openModal = !state.openModal;
    },
    modalTrue: (state) => {
      state.openModal = true;
    },
    modalFalse: (state) => {
      state.openModal = false;
    },
  },
});

export const { toggle, modalTrue, modalFalse } = modalSlice.actions;

export default modalSlice.reducer;
