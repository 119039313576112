import axios from "axios";

const BASE_URL = "https://mariposa-monarca-cholicoart.replit.app/api/";
// const TOKEN =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0ZWIyMmNiNWRhNzIwZjVkNWRhZDcyYiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY5MzEzMjAzNSwiZXhwIjoxNjkzMzkxMjM1fQ.kpGcHvrVj85KSbZufvnol8NG-XeQVerv3SL5gKpcrZk";

// const user = JSON.parse(localStorage.getItem("persist:user"));
// console.log(
//   JSON.parse(JSON.parse(localStorage.getItem("persist:user"))?.currentUser)
//     ?.accessToken
// );
// const currentUser = user && JSON.parse(user).currentUser;
// const TOKEN = currentUser?.accessToken;
const data = localStorage.getItem("persist:user");
const user = data && JSON.parse(data)?.currentUser;
const TOKEN = user ? JSON.parse(user)?.accessToken : null;

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}`, contentType: "application/json" },
});
