import videoBg from "../assets/HomeVideo.mp4";
import styled from "styled-components";
import Categories from "../components/Categories";
import HomepageProducts from "../components/HomepageProducts";
import Models from "../components/Models";
import { JewelryInCanada } from "../blogs";
import BlogHeader from "../components/BlogHeader";
import { Helmet } from "react-helmet-async";

const Container = styled.div`
  // overflow: hidden;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 575px) {
    height: 70vh;
  }
`;

const BVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
`;

const BlackShade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  background: linear-gradient(to right, rgb(0, 0, 0, 0.45), rgb(0, 0, 0, 0.45));
  z-index: 1;
`;

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Jewelry In Canada | Monarca Jewelry</title>
        <meta
          name="description"
          content="Elevate your new style with Monarca Jewels' in Canada. We help to maintain the silver tradition alive. Unlock Your Style – Find Your Perfect Piece Today!"
        />
      </Helmet>
      <Container>
        <VideoContainer>
          <BlackShade />
          <BVideo src={videoBg} type="video/mp4" autoPlay muted loop />
          <BlogHeader
            noShade={true}
            heading="Jewelry In Canada | Mariposa Monarca Jewelry"
          />
        </VideoContainer>
        <Categories />
        <HomepageProducts />
        <JewelryInCanada />
        <Models />
      </Container>
    </>
  );
};

export default Home;
