import React from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { flushUser } from "../redux/userSlice";
import { flushCart } from "../redux/cartSlice";

const Container = styled.div`
  height: 3rem;
  width: 100%;
  border-bottom: ${(props) =>
    props.t ? "0.1rem solid hsla(0, 0%, 59%, 0.36);" : "0.1px solid #505050"};
  color: ${(props) => (props.t ? "#fff" : "#080808")};
  font-size: 1.1rem;
  background-color: ${(props) => (props.t ? "transparent" : "#eba607")};
  padding-block: 0.5rem;
  overflow: visible;
  margin-top: ${(props) => (props.t ? "0.3rem" : "0")};
  margin-bottom: ${(props) => (props.t ? "0.3rem" : "0")};
  position: ${(props) => (props.t ? "absolute" : "static")};
  top: ${(props) => (props.t ? "0" : "auto")};
  // text-align: center;
  display: flex;
  // justify-content: flex-start;
  gap: 100px;
  z-index: 51;
  overflow: hidden;
  white-space: nowrap;
`;

const Text = styled.h3`
  font-size: 1.3rem;
  // color:#fff;
  padding: 0 5px;
  text-transform: capitalize;
  font-style: italic;
  font-family: auto;
  letter-spacing: 0.2em;
  animation: moveRight 10s linear infinite;
  width: 100%;

  @keyframes moveRight {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Text2 = styled.h3`
  font-size: 1.3rem;
  color: #fff;
  padding: 0 5px;
  animation: moveRight 5s linear infinite;

  @keyframes moveRight {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const Left = styled.div`
  margin-left: 1.5rem;
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
`;

const CountrySelectorContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CountrySelector = styled.span`
  font-size: 13px;
`;

const Accessibility = styled.span`
  font-size: 13px;
  cursor: pointer;
`;

const FindStore = styled.span`
  font-size: 13px;
  cursor: pointer;
`;
const Right = styled.div`
  margin-right: 3rem;
  width: 100px;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  @media (max-width: 1174px) {
    display: none;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
`;

const Button = styled.div`
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;

const Banner = (props) => {
  const user = useSelector((state) => state.persisted.user?.currentUser);
  const admin = user && user.isAdmin;
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(flushUser());
    dispatch(flushCart());
  };
  return (
    <Container t={props.transparent}>
      {/* <Left>
        <CountrySelectorContainer>
          <CountrySelector>Canada | English</CountrySelector>
          <KeyboardArrowDownIcon />
        </CountrySelectorContainer>
        <Accessibility>Accessibility</Accessibility>
        <FindStore>Find a store</FindStore>
      </Left>
      <Right>
        {!user && (
          <CustomLink to="/signup">
            <Button>signup</Button>
          </CustomLink>
        )}
        {!user && (
          <CustomLink to="/login">
            <Button>login</Button>
          </CustomLink>
        )}

        {user && <Button onClick={handleLogout}>logout</Button>}
        {admin && (
          <CustomLink to="/dashboard">
            <Button>dashboard</Button>
          </CustomLink>
        )}
      </Right> */}
      <Text>silver moments forever</Text>
      {/* <Text2>Mariposa Jewellery</Text2> */}
    </Container>
  );
};

export default Banner;
