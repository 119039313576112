import React, { useRef } from "react";

import styled from "styled-components";
import { categories } from "../data";
import CategoryItem from "./CategoryItem";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { fadeVariants } from "../animations";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
  margin-block: 5vh;

  @media screen and (max-width: 575px) {
    margin-block: 30px 20px;
  }
`;

const CategoriesContainer = styled.div`
  height: 90vh;
  display: flex;
  position: relative;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto 0;

  @media screen and (max-width: 575px) {
    height: auto;
  }
`;

const Heading = styled.h2`
  text-align: center;
  margin: 10px auto 0 auto;
  font-size: 1.9rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #080808;
  opacity: 0.9;
`;

const Line = styled.div`
  width: 130px;
  height: 4px;
  border-radius: 15px;
  background-color: #080808;
  opacity: 0.85;
  margin: auto;
`;

const CustomArrow = styled.div`
  width: 50px !important;
  height: 50px !important;
  // background-color: #fff7f7;
  background-color: #eba607;
  // border: 1px solid black;
  box-shadow: 1px 1px 15px silver;
  border-radius: 50%;
  color: #000;
  font-size: 14px;
  &::after {
    font-size: 14px !important;
    color: #000 !important;
    font-weight: 800 !important;
  }
`;

const CustomPagination = styled.div`
  bottom: 35px;

  .swiper-pagination-bullet {
    width: 9.5rem;
    height: 2px;
    background-color: #d3d3d3;
    margin: 0 4px;
    opacity: 1;
    border-radius: 0;
  }

  .swiper-pagination-bullet-active {
    background-color: black;
  }
`;

const Categories = () => {
  const swiperRef = useRef(null);

  const handleReachEnd = () => {
    // When reaching the last slide, hide the next/right arrow
    const swiperInstance = swiperRef.current?.swiper;
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const nextArrow = document.querySelector(".swiper-button-next");
      if (nextArrow) {
        nextArrow.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachBeginning = () => {
    // When reaching the first slide, hide the previous/left arrow
    const swiperInstance = swiperRef.current?.swiper;
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const prevArrow = document.querySelector(".swiper-button-prev");
      if (prevArrow) {
        prevArrow.style.display = isBeginning ? "none" : "block";
      }
    }
  };
  return (
    <Container
      variants={fadeVariants}
      initial="initial"
      // animate="animate"
      whileInView="whileInView"
    >
      <Heading>Categories</Heading>
      <Line></Line>
      <CategoriesContainer>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          // spaceBetween={20}
          // slidesPerView={1}
          // slidesPerGroup={3}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next.categories",
            prevEl: ".swiper-button-prev.categories",
          }}
          onReachEnd={handleReachEnd}
          onReachBeginning={handleReachBeginning}
        >
          {categories.map((item) => (
            <SwiperSlide key={item.id}>
              <CategoryItem item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        <CustomArrow className="swiper-button-next categories"></CustomArrow>
        <CustomArrow className="swiper-button-prev categories"></CustomArrow>
        <CustomPagination className="swiper-pagination" />
      </CategoriesContainer>
    </Container>
  );
};

export default Categories;
