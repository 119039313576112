import { configureStore, combineReducers } from "@reduxjs/toolkit";
import modalReducer from "./modalSlice";
import cartReducer from "./cartSlice";
import userReducer from "./userSlice";
import productReducer from "./productSlice";
import dashboardUserReducer from "./dashboardUsersSlice";
import orderReducer from "./orderSlice";
import reloadReducer from "./reloadSlice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const userConfig = {
  key: "user",
  version: 1,
  storage,
};
const cartConfig = {
  key: "cart",
  version: 1,
  storage,
};
const productConfig = {
  key: "product",
  version: 1,
  storage,
};
const dashboardUsersConfig = {
  key: "dashboardUsers",
  version: 1,
  storage,
};
const orderConfig = {
  key: "order",
  version: 1,
  storage,
};
const combined = combineReducers({
  user: persistReducer(userConfig, userReducer),
  cart: persistReducer(cartConfig, cartReducer),
  product: persistReducer(productConfig, productReducer),
  dashboardUsers: persistReducer(dashboardUsersConfig, dashboardUserReducer),
  order: persistReducer(orderConfig, orderReducer),
});

const rootReducer = combineReducers({
  persisted: combined,
  modal: modalReducer,
  reload: reloadReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
