import { createSlice } from "@reduxjs/toolkit";

export const blogSlice = createSlice({
  name: "product",
  initialState: {
    blogs: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET ALL
    getBlogStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getBlogSuccess: (state, action) => {
      state.isFetching = false;
      state.blogs = action.payload;
    },
    getBlogFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { getBlogStart, getBlogSuccess, getBlogFailure } =
  blogSlice.actions;

export default blogSlice.reducer;
