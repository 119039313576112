import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    quantity: 0,
    originalTotal: 0,
    total: 0,
    coupon: null,
    sessionId: "",
  },
  reducers: {
    addProduct: (state, action) => {
      state.quantity += 1;
      state.products.push(action.payload);
      state.total += action.payload.totalPrice;
      state.originalTotal += action.payload.totalPrice;
      //   action.payload.quantity;
    },
    flushCart: (state) => {
      state.quantity = 0;
      state.products = [];
      state.total = 0;
      state.coupon = null;
      state.originalTotal = 0;
    },
    incrementProductQuantity: (state, action) => {
      const { id, size } = action.payload;
      const itemIndex = state.products.findIndex(
        (item) => item._id === id && item.selectedSize?.label === size
      );
      const currentProduct = state.products[itemIndex];
      currentProduct.quantity += 1;
      currentProduct.totalPrice =
        currentProduct.discountPercentage > 0
          ? (currentProduct.price -
              (currentProduct.price * currentProduct.discountPercentage) /
                100) *
            currentProduct.quantity
          : currentProduct.price * currentProduct.quantity;
    },
    decrementProductQuantity: (state, action) => {
      const { id, size } = action.payload;
      const itemIndex = state.products.findIndex(
        (item) => item._id === id && item.selectedSize?.label === size
      );
      let quan = state.products[itemIndex].quantity;
      if (quan > 1) {
        const currentProduct = state.products[itemIndex];
        currentProduct.quantity -= 1;
        currentProduct.totalPrice -=
          currentProduct.discountPercentage > 0
            ? currentProduct.price -
              (currentProduct.price * currentProduct.discountPercentage) / 100
            : currentProduct.price;
      }
    },
    updateCartTotal: (state) => {
      state.total = 0;
      state.originalTotal = 0;
      state.products.map((prod) => {
        state.total += prod.totalPrice;
        state.originalTotal += prod.totalPrice;
      });
    },
    deleteProduct: (state, action) => {
      const { id, size } = action.payload;
      const itemIndex = state.products.findIndex(
        (item) => item._id === id && item.selectedSize?.label === size
      );
      const { totalPrice } = state.products[itemIndex];
      state.products.splice(itemIndex, 1);
      const newTotal = state.total - totalPrice;
      const newOriginalTotal = state.originalTotal - totalPrice;
      state.total = newTotal > 0 ? newTotal : 0;
      state.originalTotal = newOriginalTotal > 0 ? newOriginalTotal : 0;
      state.quantity -= 1;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    applyDiscount: (state, action) => {
      const discount = action.payload;
      state.total = state.total - state.total * (discount / 100);
    },
    setCoupon: (state, action) => {
      if (action.payload === null) {
        state.total = state.originalTotal;
      }
      state.coupon = action.payload;
    },
  },
});

export const {
  addProduct,
  flushCart,
  incrementProductQuantity,
  decrementProductQuantity,
  updateCartTotal,
  deleteProduct,
  setSessionId,
  applyDiscount,
  setCoupon,
} = cartSlice.actions;
export default cartSlice.reducer;
