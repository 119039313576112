import { lazy } from "react";

const JewelryInCanada = lazy(() => import("./JewelryInCanada/JewelryInCanada"));
const SilverChains = lazy(() => import("./SilverChains/SilverChains"));
const SilverCharms = lazy(() => import("./SilverCharms/SilverCharms"));
const SilverEarrings = lazy(() => import("./SilverEarrings/SilverEarrings"));
const SilverRings = lazy(() => import("./SilverRings/SilverRings"));
const SilverSets = lazy(() => import("./SilverSets/SilverSets"));
const EngagementRings = lazy(() => import("./EngagementRings/EngagementRings"));
const CoupleRings = lazy(() => import("./CoupleRings/CoupleRings"));
const CanadianRingSizes = lazy(() =>
  import("./CanadianRingSizes/CanadianRingSizes")
);
const AntiqueSilverCharms = lazy(() =>
  import("./AntiqueSilverCharms/AntiqueSilverCharms")
);
const TrendingJewelryStyles = lazy(() =>
  import("./TrendingJewelryStyles/TrendingJewelryStyles")
);
const WeddingRings = lazy(() => import("./WeddingRings/WeddingRings"));
const PerfectSilverJewelry = lazy(() =>
  import("./PerfectSilverJewelry/PerfectSilverJewelry")
);
const CaringSilverJewelry = lazy(() =>
  import("./CaringSilverJewelry/CaringSilverJewelry")
);
const ArtOfLayering = lazy(() => import("./ArtOfLayering/ArtOfLayering"));
const WeddingSilverJewelry = lazy(() =>
  import("./WeddingSilverJewelry/WeddingSilverJewelry")
);
const NecklacesCanada = lazy(() => import("./NecklaceCanada/NecklaceCanada"));
const ValentineGift = lazy(() => import("./ValentineGift/ValentineGift"));

export {
  JewelryInCanada,
  SilverChains,
  SilverCharms,
  SilverEarrings,
  SilverRings,
  SilverSets,
  EngagementRings,
  CoupleRings,
  CanadianRingSizes,
  AntiqueSilverCharms,
  TrendingJewelryStyles,
  WeddingRings,
  PerfectSilverJewelry,
  CaringSilverJewelry,
  ArtOfLayering,
  WeddingSilverJewelry,
  NecklacesCanada,
  ValentineGift,
};
