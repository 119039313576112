import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { styled } from "styled-components";
import Sidebar from "../components/Sidebar";
import Loading from "../components/Loading";

const Container = styled.div`
  display: flex;
  position: relative;
`;

export const AdminRoutes = () => {
  const user = useSelector((state) => state.persisted.user?.currentUser);
  const admin = user && user?.isAdmin;

  if (!admin) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Sidebar />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Container>
  );
};
