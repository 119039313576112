import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Container = styled(motion.div)`
  flex: 1;
  margin: 5px;
  height: 80vh;
  width: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding-bottom: 5px;

  @media screen and (max-width: 400px) {
    height: auto;
    padding-bottom: 10px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 60vh;
  display: block;
  object-fit: cover;
  min-width: 60vh;

  @media screen and (max-width: 400px) {
    min-width: auto;
    max-height: 50vh;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.h2`
  margin: 10px auto;
  // font-family: "DM Sans", sans-serif;
  color: #080808;
  font-weight: 500;
  text-transform: uppercase;

  @media screen and (max-width: 575px) {
    font-size: 1.3rem;
  }
`;
const Button = styled.button`
  width: 90%;
  display: block;
  padding: 13px 5px;
  font-size: 1rem;
  background-color: #eba607;
  color: #080808;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px auto 0 auto;

  &:hover {
    opacity: 0.9;
  }
`;

const CustomLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
`;

const CategoryItem = ({ item }) => {
  return (
    <Container
      transition={{ duration: 0.5 }}
      whileHover={{ y: -15, transition: { duration: 0.5 } }}
    >
      <Image src={item.img} loading="lazy" />
      <Info>
        <Title>{item.title}</Title>
      </Info>
      <CustomLink to={`products/?category=${item.cat}`}>
        <Button>SHOP NOW</Button>
      </CustomLink>
    </Container>
  );
};

export default CategoryItem;
