import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  // padding: 60px 10px;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  gap: 15px;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 575px) {
    height: 30vh;
  }

  @media screen and (max-width: 319px) {
    height: 25vh;
  }
`;
const HeadingText = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  color: #fff;

  @media screen and (max-width: 575px) {
    font-size: 1.2rem;
  }
`;

const Line = styled.div`
  width: 150px;
  height: 5px;
  border-radius: 15px;
  background-color: #fff;
`;

const LinkRoute = styled.small`
  font-size: 0.75rem;
  font-weight: 500;
  color: #fff;
  display: block;
  text-align: center;

  @media screen and (max-width: 575px) {
    font-size: 0.7rem;
  }
`;

const BlogHeader = ({ heading, img, noShade }) => {
  return (
    <Container
      style={{
        backgroundImage:
          !noShade &&
          `linear-gradient(to right, rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.4)),url(${img})`,
      }}
    >
      <HeadingText>{heading}</HeadingText>
      <Line></Line>
      <LinkRoute>Home / {heading}</LinkRoute>
    </Container>
  );
};

export default BlogHeader;
