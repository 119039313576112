import { createSlice } from "@reduxjs/toolkit";

export const reloadSlice = createSlice({
  name: "reload",
  initialState: {
    reload: false,
  },
  reducers: {
    toggle: (state) => {
      state.reload = !state.openModalreload;
    },
  },
});

export const { toggle } = reloadSlice.actions;

export default reloadSlice.reducer;
