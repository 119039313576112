export const fadeVariants = {
  initial: { y: 100, opacity: 0 },
  // animate:{y: 0,opacity:1,transition:{duration: 0.75}},
  whileInView: { y: 0, opacity: 1, transition: { duration: 0.75 } },
};

export const zoomVariants = {
  initial: { scale: 0.7, opacity: 0 },
  // animate:{y: 0,opacity:1,transition:{duration: 0.75}},
  whileInView: { scale: 1, opacity: 1, transition: { duration: 0.75 } },
};
