import styled from "styled-components";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import { blogs, categories } from "../data";
import logo from "../assets/logo-monarca-negativo.png";

const TikTokIcon = ({ color = "#777" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="25px"
      height="25px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const CustomLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: inherit;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: #080808;
`;
const Top = styled.div`
  width: 100%;
  height: 4rem;
  // background-color: #131010;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 3px;
  border-bottom: 0.1px solid gray;
`;

const Brand = styled.img`
  height: 60px;
  width: 120px;
`;

const SocialIcons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  margin: 20px 0 10px;
  gap: 28px;
`;
const SocialLink = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  color: gray;
`;
const Bottom = styled.div`
  // width: 100%;
  // height: 30rem;
  // background-color: #131010;
  padding: 30px 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 40px;
  }

  @media screen and (max-width: 475px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 30px 60px;
  }
`;
const FooterItem = styled.div`
  padding: 4px;
  justify-self: center;

  @media screen and (max-width: 768px) {
    justify-self: flex-start;
  }

  @media screen and (max-width: 475px) {
    justify-self: flex-start;
  }
`;
const Title = styled.h2`
  font-family: "DM Sans", sans-serif;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 2.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
`;
const ListItem = styled.li`
  margin-bottom: 1rem;
`;
const ListItemLink = styled(Link)`
  font-family: "Open Sans", sans-serif;
  color: gray;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  line-height: 1.8rem;

  &:hover {
    color: #f2f2f2;
    transition: 0.3s;
  }
`;
const Last = styled.div`
  width: 100%;
  padding: 10px;
  position: relative;
  text-align: center;
  border-top: 1px solid #505050;
`;
const CopyrightText = styled.span`
  color: gray;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0 20px;
  dispaly: block;
`;
const ArrowContainer = styled.div`
  width: 25px;
  height: 25px;
  border: 0.5px solid gray;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  right: 1.5rem;
  cursor: pointer;
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  color: gray;
  margin: 20px 0;
`;

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const legalTermsLinks = [
  { title: "Privacy Policy", link: "/privacyPolicy" },
  { title: "Cookie Policy", link: "/cookiePolicy" },
  { title: "Terms and Conditions", link: "/termsAndConditions" },
  { title: "Return Policy", link: "/returnPolicy" },
  { title: "Disclaimer", link: "/disclaimer" },
  { title: "Shipping Policy", link: "/shippingPolicy" },
  { title: "Acceptable Reuse Policy", link: "/acceptableReusePolicy" },
];

const companyLinks = [
  { title: "About Us", link: "/about" },
  { title: "Contact Us", link: "/contact" },
  { title: "Ambassador and Wholesale", link: "/ambassador-and-wholesale" },
];

const facebookLink = "https://www.facebook.com/MonarcaJewelry?mibextid=9R9pXO";

const instagramLink =
  "https://instagram.com/monarcajewels?igshid=NTc4MTIwNjQ2YQ==";

const tiktokLink = "https://www.tiktok.com/@monarcajewels?_t=8fjctHQFfAm&_r=1";

const Footer = () => {
  return (
    <Container>
      <Top>
        <Brand alt="logo" src={logo} />
      </Top>
      <Bottom>
        <FooterItem>
          <Title>collections</Title>
          <List>
            {categories.map((category) => (
              <ListItem>
                <ListItemLink to={`/products?category=${category.cat}`}>
                  {category.title}
                </ListItemLink>
              </ListItem>
            ))}
          </List>
        </FooterItem>
        <FooterItem>
          <Title>Blogs</Title>
          <List>
            {blogs?.map((blog) => (
              <ListItem>
                <ListItemLink to={blog.link}>{blog.title}</ListItemLink>
              </ListItem>
            ))}
          </List>
        </FooterItem>
        <FooterItem>
          <Title>Our Company</Title>
          <List>
            {companyLinks?.map((item) => (
              <ListItem>
                <ListItemLink to={item.link}>{item.title}</ListItemLink>
              </ListItem>
            ))}
          </List>
        </FooterItem>
        <FooterItem>
          <Title>Legal Terms</Title>
          <List>
            {legalTermsLinks?.map((item) => (
              <ListItem>
                <ListItemLink to={item.link}>{item.title}</ListItemLink>
              </ListItem>
            ))}
          </List>
        </FooterItem>
      </Bottom>
      <Last>
        <BottomLinks>
          <CustomLink to="/">Home</CustomLink>|
          <CustomLink to="/blog">Blog</CustomLink>|
          <CustomLink to="/about">About Us</CustomLink>|
          <CustomLink to="/contact">Contact Us</CustomLink>
        </BottomLinks>
        <CopyrightText>
          © Mariposa Monarca Jewelry Inc. {new Date().getFullYear()}.
        </CopyrightText>
        <BottomLinks>
          <CustomLink to="/termsAndConditions">Terms & Conditions</CustomLink>|
          <CustomLink to="/privacyPolicy">Privacy Policy</CustomLink>|
          <CustomLink to="/sitemap">Sitemap</CustomLink>
        </BottomLinks>
        <SocialIcons>
          <CustomLink to={facebookLink} target="_blank">
            <SocialLink>
              <FacebookIcon />
            </SocialLink>
          </CustomLink>
          <CustomLink to={instagramLink} target="_blank">
            <SocialLink>
              <InstagramIcon />
            </SocialLink>
          </CustomLink>
          <CustomLink to={tiktokLink} target="_blank">
            <SocialLink>
              <TikTokIcon />
            </SocialLink>
          </CustomLink>
        </SocialIcons>
        <ArrowContainer onClick={scrollToTop}>
          <KeyboardArrowUpIcon style={{ color: "white" }} />
        </ArrowContainer>
      </Last>
    </Container>
  );
};

export default Footer;
