import {
  publicRequest,
  useRedirectOnInvalidToken,
  userRequest,
} from "../requestMethods";
import {
  clearError,
  loginFailure,
  loginStart,
  loginSuccess,
} from "./userSlice";
import {
  getProductStart,
  getProductFailure,
  getProductSuccess,
  deleteProductFailure,
  deleteProductStart,
  deleteProductSuccess,
  updateProductFailure,
  updateProductStart,
  updateProductSuccess,
  addProductFailure,
  addProductStart,
  addProductSuccess,
} from "./productSlice";
import {
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  getUserFailure,
  getUserStart,
  getUserSuccess,
  updateUserFailure,
  updateUserStart,
  updateUserSuccess,
} from "./dashboardUsersSlice";
import {
  getOrdersFailure,
  getOrdersStart,
  getOrdersSuccess,
  deleteOrdersFailure,
  deleteOrdersStart,
  deleteOrdersSuccess,
  updateOrdersFailure,
  updateOrdersStart,
  updateOrdersSuccess,
} from "./orderSlice";

import { getBlogStart, getBlogFailure, getBlogSuccess } from "./blogSlice";

export const login = async (dispatch, user, setLocalError) => {
  dispatch(loginStart);
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
    return false;
  } catch (err) {
    dispatch(loginFailure());
    return true;
  }
};

export const signup = async (dispatch, user) => {
  let signupAttempt;
  dispatch(loginStart);
  try {
    const res = await publicRequest.post("/auth/register", user);
    dispatch(loginSuccess(res.data));
    signupAttempt = true;
  } catch (err) {
    dispatch(loginFailure());
    signupAttempt = false;
  }
  return signupAttempt;
};

export const getProducts = async (dispatch) => {
  dispatch(getProductStart());
  try {
    const res = await publicRequest.get("/products");
    dispatch(getProductSuccess(res.data?.products));
    console.log(res.data?.products);
  } catch (err) {
    dispatch(getProductFailure());
  }
};

export const deleteProduct = async (id, dispatch) => {
  dispatch(deleteProductStart());
  try {
    const res = await userRequest.delete(`/products/${id}`);
    dispatch(deleteProductSuccess(id));
  } catch (err) {
    dispatch(deleteProductFailure());
  }
};

export const updateProduct = async (id, product, dispatch) => {
  dispatch(updateProductStart());
  try {
    // update
    const res = await userRequest.put(`/products/${id}`, product);
    dispatch(updateProductSuccess({ id, product }));
    return res;
  } catch (err) {
    dispatch(updateProductFailure());
  }
};

export const updateProductInterestedCustomers = async (
  id,
  product,
  sizes,
  dispatch
) => {
  dispatch(updateProductStart());
  try {
    // update
    const res = await userRequest.put(
      `/products/interestedCustomer/${id}`,
      sizes
    );
    dispatch(updateProductSuccess({ id, product }));
    return res;
  } catch (err) {
    dispatch(updateProductFailure());
  }
};
export const addProduct = async (product, dispatch) => {
  dispatch(addProductStart());
  try {
    const res = await userRequest.post(`/products`, product);

    dispatch(addProductSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(addProductFailure());
  }
};

export const getUsers = async (dispatch) => {
  dispatch(getUserStart());
  try {
    const res = await userRequest.get("/users");
    dispatch(getUserSuccess(res.data));
  } catch (err) {
    dispatch(getUserFailure());
  }
};

export const deleteUser = async (id, dispatch) => {
  dispatch(deleteUserStart());
  try {
    const res = await userRequest.delete(`/users/${id}`);
    dispatch(deleteUserSuccess(id));
  } catch (err) {
    dispatch(deleteUserFailure());
  }
};

export const updateUser = async (id, user, dispatch) => {
  dispatch(updateUserStart());
  try {
    // update
    const res = await userRequest.put(`/users/${id}`, user);
    dispatch(updateUserSuccess({ id, user }));
  } catch (err) {
    dispatch(updateUserFailure());
  }
};

export const getUserCoupons = async (id) => {
  try {
    const res = await userRequest.get(`/users/coupon/${id}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getOrders = async (dispatch) => {
  dispatch(getOrdersStart());
  try {
    const res = await userRequest.get("/orders");
    dispatch(getOrdersSuccess(res.data));
  } catch (err) {
    dispatch(getOrdersFailure());
  }
};

export const deleteOrder = async (id, dispatch) => {
  dispatch(deleteOrdersStart());
  try {
    const res = await userRequest.delete(`/orders/${id}`);
    dispatch(deleteOrdersSuccess(id));
  } catch (err) {
    dispatch(deleteOrdersFailure());
  }
};

export const updateOrder = async (id, order, dispatch) => {
  dispatch(updateOrdersStart());
  try {
    // update
    const res = await userRequest.put(`/orders/${id}`, order);
    dispatch(updateOrdersSuccess({ id, order }));
  } catch (err) {
    dispatch(updateOrdersFailure());
  }
};

export const getBlog = async (dispatch) => {
  dispatch(getBlogStart());
  try {
    // update
    const res = await publicRequest.get(`/blogs`);
    return res.data;
    // dispatch(getBlogSuccess(blog));
  } catch (err) {
    // dispatch(getBlogFailure());
    console.error(err);
  }
};

export const updateBlog = async (blog, dispatch) => {
  dispatch(getBlogStart());
  try {
    // update
    const res = await userRequest.post(`/blogs`, blog);
    dispatch(getBlogSuccess(blog));
    return res;
  } catch (err) {
    dispatch(getBlogFailure());
  }
};

export const getCoupons = async () => {
  try {
    const res = await userRequest.get(`/coupon`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const getSingleCoupon = async (id) => {
  try {
    const res = await userRequest.get(`/coupon/${id}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};

export const createCoupon = async (coupon) => {
  try {
    const res = await userRequest.post(`/coupon`, coupon);
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const updateCoupon = async (id, updatedCoupon) => {
  try {
    const res = await userRequest.put(`/coupon/${id}`, updatedCoupon);
    return res;
  } catch (err) {
    console.error(err);
  }
};

export const deleteCoupon = async (id) => {
  try {
    const res = await userRequest.delete(`/coupon/${id}`);
    if (res.status === 200) {
      return res.status;
    }
  } catch (err) {
    console.error(err);
  }
};

export const verifyCoupon = async (code) => {
  try {
    const res = await userRequest.get(`/coupon/verify/${code}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.error(err);
  }
};
