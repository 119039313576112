import { Outlet, useLocation } from "react-router-dom";
import ChatBot from "../components/ChatBot";
import { namespaces } from "../data";
import { useEffect } from "react";

export const PublicRoutes = () => {
  const { pathname, search } = useLocation();
  const url = pathname + search;
  const renderBot = namespaces.find((item) => item.link.includes(url));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname, search]);

  return (
    <div style={{ position: "relative", zIndex: 1 }}>
      <Outlet />
      {renderBot && <ChatBot />}
    </div>
  );
};
