import earrings from "../src/assets/Earrings-cat.jpg";
import bracelets from "../src/assets/categories/Bracelets-cat.jpg";
import charms from "../src/assets/categories/pendant_charm-cat.JPEG";
import necklacesNav from "../src/assets/necklaces-nav.jpg";
import sets from "../src/assets/categories/Sets-cat.JPEG";
import rings from "../src/assets/categories/Rings-cat.JPEG";
import specialSales from "../src/assets/Special sale.JPEG";
import pendants from "../src/assets/pendant_charm.JPEG";
// import ringsNav from "../src/assets/rings-nav.jpeg";
// import charmsNav from "../src/assets/charms-nav.JPEG";
import ringsInCanadaImg from "../src/blogs/SilverRings/assets/heading-pic.png";
import silverCharmsImg from "../src/blogs/SilverCharms/assets/heading-pic.png";
import jewelrySetsImg from "../src/blogs/SilverSets/assets/heading-pic.png";
import silverEarringsImg from "../src/blogs/SilverEarrings/assets/heading-pic.png";
import engagementRingsImg from "../src/blogs/EngagementRings/assets/heading-pic.jpg";
import coupleRingsImg from "../src/blogs/CoupleRings/assets/heading-pic.png";
import canadianRingSizesImg from "../src/blogs/CanadianRingSizes/assets/heading-pic.jpg";
import antiqueSilverCharmsImg from "../src/blogs/AntiqueSilverCharms/assets/heading-pic.jpg";
import trendingJewelryStylesImg from "../src/blogs/TrendingJewelryStyles/assets/heading-pic.jpg";
import weddingRingsImg from "../src/blogs/WeddingRings/assets/heading-pic.png";
import perfectSilverJewelryImg from "../src/blogs/PerfectSilverJewelry/assets/heading-pic.png";
import caringSilverJewelryImg from "../src/blogs/CaringSilverJewelry/assets/heading-pic.jpg";
import artOfLayeringImg from "../src/blogs/ArtOfLayering/assets/heading-pic.png";
import weddingSilverJewelryImg from "../src/blogs/WeddingSilverJewelry/assets/heading-pic.png";
import necklaceCanadaImg from "../src/blogs/NecklaceCanada/assets/heading-pic.png";
import silverChainsImg from "../src/blogs/SilverChains/assets/heading-pic.png";
import valentineGiftImg from "../src/blogs/ValentineGift/assets/first.png";

export const categories = [
  {
    id: 1,
    img: rings,
    title: "Rings",
    cat: "rings",
  },
  {
    id: 2,
    img: specialSales,
    title: "Special Sales",
    cat: "special sales",
  },
  {
    id: 3,
    img: necklacesNav,
    title: "Necklaces",
    cat: "necklaces",
  },
  {
    id: 4,
    img: earrings,
    title: "Earrings",
    cat: "earrings",
  },
  {
    id: 5,
    img: bracelets,
    title: "Bracelets",
    cat: "bracelets",
  },
  {
    id: 6,
    img: sets,
    title: "Sets",
    cat: "sets",
  },
  {
    id: 7,
    img: charms,
    title: "Charms",
    cat: "charms",
  },
];

const blogBaseUrl = "/blog";

export const blogs = [
  {
    title: "Sterling Silver Rings Canada",
    img: ringsInCanadaImg,
    cat: "Rings",
    link: `${blogBaseUrl}/rings-in-canada`,
  },
  {
    title: "Silver Charms for Bracelets",
    img: silverCharmsImg,
    cat: "Charms",
    link: `${blogBaseUrl}/silver-charms-for-bracelets`,
  },
  {
    title: "Jewelry Sets for Women",
    img: jewelrySetsImg,
    cat: "Sets",
    link: `${blogBaseUrl}/jewelry-sets-for-women`,
  },
  {
    title: "Silver Earrings Canada",
    img: silverEarringsImg,
    cat: "Earrings",
    link: `${blogBaseUrl}/silver-earrings-canada`,
  },
  {
    title: "Engagement Rings Canada",
    img: engagementRingsImg,
    cat: "Rings",
    link: `${blogBaseUrl}/engagement-rings-in-canada`,
  },
  {
    title: "Couple Rings Canada",
    img: coupleRingsImg,
    cat: "Rings",
    link: `${blogBaseUrl}/couple-rings-canada`,
  },
  {
    title: "Canadian Ring Sizes",
    img: canadianRingSizesImg,
    cat: "Rings",
    link: `${blogBaseUrl}/canadian-ring-sizes`,
  },
  {
    title: "Antique Silver Charms",
    img: antiqueSilverCharmsImg,
    cat: "Charms",
    link: `${blogBaseUrl}/antique-silver-charms`,
  },
  {
    title: "Trending Jewelry Styles",
    img: trendingJewelryStylesImg,
    cat: "Jewelry",
    link: `${blogBaseUrl}/trending-jewelry-styles`,
  },
  {
    title: "Wedding Rings in Canada",
    img: weddingRingsImg,
    cat: "Rings",
    link: `${blogBaseUrl}/wedding-rings-in-canada`,
  },
  {
    title: "Choosing the Perfect Silver Jewelry for Your Skin Tone",
    img: perfectSilverJewelryImg,
    cat: "Jewelry",
    link: `${blogBaseUrl}/choosing-the-perfect-silver-jewelry-for-your-skin-tone`,
  },
  {
    title: "Caring for Your Silver Jewelry: Tips and Tricks",
    img: caringSilverJewelryImg,
    cat: "Jewelry",
    link: `${blogBaseUrl}/caring-for-your-silver-jewelry`,
  },
  {
    title: "The Art of Layering: Creating Stylish Silver Jewelry Stacks",
    img: artOfLayeringImg,
    cat: "Jewelry",
    link: `${blogBaseUrl}/the-art-of-layering:creating-stylish-silver-jewelry-stacks`,
  },
  {
    title: "Silver Jewelry for a Wedding Day",
    img: weddingSilverJewelryImg,
    cat: "Jewelry",
    link: `${blogBaseUrl}/silver-jewelry-for-a-wedding-day`,
  },
  {
    title: "Necklace in Canada",
    img: necklaceCanadaImg,
    cat: "Necklaces",
    link: `${blogBaseUrl}/silver-necklace-women-canada`,
  },
  {
    title: "Silver Chain Mens Canada",
    img: silverChainsImg,
    cat: "Necklaces",
    link: `${blogBaseUrl}/silver-chain-mens-canada`,
  },
  {
    title: "Perfect Valentine's Day Gift Online",
    img: valentineGiftImg,
    cat: "Jewelry",
    link: `${blogBaseUrl}/perfect-valentines-day-gift`,
  },
];

export const gallery = [
  rings,
  specialSales,
  necklacesNav,
  rings,
  specialSales,
  sets,
  pendants,
];

export const sizes = [
  { label: "sm", value: "Small" },
  { label: "md", value: "Medium" },
  { label: "lg", value: "Large" },
];

export const namespaces = [
  { title: "Home", category: "general", link: ["/"] },
  { title: "Contact", category: "general", link: ["/contact"] },
  { title: "About", category: "general", link: ["/about"] },
  { title: "Categories", category: "general", link: ["/products"] },
  { title: "Rings", category: "product", link: ["/products?category=rings"] },
  {
    title: "Necklaces",
    category: "product",
    link: ["/products?category=necklaces"],
  },
  { title: "Charms", category: "product", link: ["/products?category=charms"] },
  { title: "Sets", category: "product", link: ["/products?category=sets"] },
  {
    title: "Special Sales",
    category: "product",
    link: ["/products?category=special%20sales"],
  },
  {
    title: "Bracelets",
    category: "product",
    link: ["/products?category=bracelets"],
  },
  {
    title: "Earrings",
    category: "product",
    link: ["/products?category=earrings"],
  },
  {
    title: "Legal Terms",
    category: "general",
    link: [
      "/privacyPolicy",
      "/cookiePolicy",
      "/termsAndConditions",
      "/returnPolicy",
      "/disclaimer",
      "/shippingPolicy",
      "/acceptableReusePolicy",
    ],
  },
];
