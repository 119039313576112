import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { setSessionId } from "./redux/cartSlice";
import AppRoutes from "./routes";

function App() {
  const sessionId = useSelector((state) => state.persisted.cart.sessionId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionId) {
      dispatch(setSessionId(uuidv4()));
    }
  }, []);

  return <AppRoutes />;
}

export default App;
